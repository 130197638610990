import React from "react";
import {
    EdgeContainer,
    EdgeContent
} from "./styles";

export const Edge = () => {
    const features = [
        "Аирдроп, в котором можно найти много полезного.",
        "Электронный кодовый замок.",
        "Стройка ванильная + кастомная. \n" +
        "(Большие ворота, маленькие двери, оконные щиты).",
        "Рейд тайм. \n" +
        "Пн-Пт 16:00 - 00:00 \n" +
        "Cб-Вс 12:00 - 00:00",
        "Рейд только по воротам.",
        "Беруши \n" +
        "(Активируются на N).",
        "Карта \n" +
        "(Открывается на M).",
        "Фурнитура.",
        "Возможность объединять патроны до 100 шт.",
        "Возможность объединять предметы такие, как скотч, комплекты для чистки оружия и т.п.",
        "Открытие инвентаря в транспорте.",
        "Маскировочные костюмы надеваются в слот ремня или повязки.",
        "Без патимода \n" +
        "(Используйте повязки).",
        "Лутания предметов удержанием правой кнопки мыши (ПКМ). \n " +
        " Удерживайте ПКМ при наведении курсора на предметы, чтобы сложить предметы в свой инвентарь или в контейнеры. \n" +
        " SHIFT + ПКМ, чтобы переместить предметы на землю."
    ];

    return(
        <EdgeContainer id='features'>
            <EdgeContent>
                <h2>Особенности Сервера</h2>
                <ul>
                    {features.map((feature, index) => (
                        <li key={index}>{feature}</li>
                    ))}
                </ul>
            </EdgeContent>
        </EdgeContainer>
    )
}