import React, {useState} from "react";
import Opened from '../../../assets/img/icons/open.svg';
import Closed from '../../../assets/img/icons/close.svg';
import {
    RulesContainer,
    RulesContent,
    RulesTitle,
    RulesAccordion,
    RulesAccordionHeader,
    RulesAccordionItem,
    RulesAccordionContent,
    Clear,
    ClearOne
} from './styles';

export const Rules = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleAccordionClick = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const rules = [
        {
            title: "1. Общие правила",
            content: "1.1. Запрещено оскорбление, упоминание родителей, клевета на администрацию и проект в целом, разжигание политической и межнациональной розни.\n" +
                "1.2 Запрещено использование багов игры.\n" +
                "1.3 Запрещено использование и хранение стороннего ПО, или ПО для получения преимущества над другими игроками, не использующих данное ПО(сюда попадают: программы для отключения травы/текстур,  -чит и -хаксофты. Кроме: ПО для прицела, выкручивания яркости/гаммы).\n" +
                "1.4 Запрещено выходить с сервера во время вызова/в ходе проверки Вас администрацией на честность игры.\n" +
                "1.5 Запрещено играть с более 1-ого аккаунта/мультиаккаунта(под это правило попадают: аккаунты стим после блокировки основного, аккаунты друзей и т.п.). \n" +
                "1.6 Запрещена продажа внутриигровых предметов за деньги.\n" +
                "1.7 Запрещено рекламировать сторонние ресурсы (Сайты, Группы, Сервера и т.п.).",
        },
        {
            title: "2. Правила строительства баз",
            content: "2.1. Запрещено застраивать: военные зоны, больницы, полицейские участки, магазины и охотничьи лагеря.\n" +
                "2.2. Запрещено загораживать проходы фурнитурой/бочками/палатками и т.д.\n" +
                "2.3. Запрещено застраиваться на расстоянии менее чем 500 метров от военных баз (одиночные пары палаток/контейнеров не подходят под это правило).\n" +
                "Примечание: мерить от ближайшего забора (если его нет, то от ближайшего строения).\n" +
                "2.4. Запрещено строительство/размещение предметов (имеющих коллизию) на любых дорогах.\n" +
                "2.5. Запрещено строительство стен/вышек более 3-х этажей.\n" +
                "2.6. Запрещено строить забор в заборе друг в друге, необходимое расстояние между постройками: что бы персонаж стоял и двигался свободно. \n" +
                "Примечание: постройки которые будут нарушать правила будут удалены без компенсации и разбора.\n" +
                "2.7. Запрещено возводить любые конструкции, нарушая законы физики.\n" +
                "Примечание: постройки которые будут нарушать правила будут удалены без компенсации и разбора.\n" +
                "2.8. Запрещено застраивать статичные двери, таким способом, чтобы их нельзя было открыть.\n" +
                "2.9. Запрещено хранить лут в текстурах.",
        },
        {
            title: "3. Правила рейда баз",
            content: "3.1. Запрещено застраивать входы чужих баз.\n" +
                "3.2. Запрещено ставить кодлоки на фурнитуру. Поставленные кодлоки на фурнитуру будут удаляться.\n" +
                "3.3. Застраивать вражескую базу, можно только в случае уничтожения всех ворот с замками.\n" +
                "3.4. Запрещено рейдить с помощью размещения в воздухе/лесенкой/друг на друге следующих предметов: фурнитуры, бочек, палаток, заборов, вышек и т.п.\n" +
                "3.5. Запрещено выходить из игры во время рейда или боя (рейд считается оконченным если с вами и с вашей базой не происходит никаких манипуляций в течении 10 минут/бой считается оконченным если с вами не происходит никаких манипуляций в течении 5 минут.\n" +
                "3.6. Запрещено застраивать базы во время рейда. (Рейд считается оконченным если с базой не происходит никаких манипуляций в течении 10 минут.\n" +
                "3.7. Если вы вышли на не застроенной базе и зашли , и она застроена , то вам необходимо сообщить администрации ,что бы вас вытащили либо же договорится с хозяином базы , что бы он вас выпустил. При этом вам запрещено трогать/руинить лут в базе и убивать хозяина базы.\n" +
                "3.8. При рейде ключевых моментов (пролаза в окна, подсадки , взлом кодлоков и т.д) - делайте запись этих моментов.",
        }
    ];

    return(
        <RulesContainer id='rules'>
            <RulesContent>
                <Clear/>
                <RulesTitle>
                    <h2>Правила</h2>
                </RulesTitle>
                <RulesAccordion>
                    {rules.map((rule, index) => (
                        <RulesAccordionItem key={index}>
                            <RulesAccordionHeader isActive={activeIndex === index} onClick={() => handleAccordionClick(index)}>
                                <span>{rule.title}</span>
                                <img
                                    src={activeIndex === index ? Opened : Closed}
                                    alt={activeIndex === index ? "Opened" : "Closed"}
                                />
                            </RulesAccordionHeader>
                            <RulesAccordionContent open={activeIndex === index}>
                                {rule.content}
                            </RulesAccordionContent>
                        </RulesAccordionItem>
                    ))}
                </RulesAccordion>
                <ClearOne/>
            </RulesContent>
        </RulesContainer>
    )
}