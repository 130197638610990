import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  background: rgba(30, 31, 34, 0.7);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 100;

  @media (min-width : 1440px) and (max-width : 1628px){
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    background: rgba(30, 31, 34, 0.7);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }
  @media (min-width : 1200px) and (max-width : 1439px){
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    background: rgba(30, 31, 34, 0.7);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }
  @media (min-width : 992px) and (max-width : 1199px){
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: rgba(30, 31, 34, 0.7);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }
  @media (min-width : 768px) and (max-width : 991px){
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: rgba(30, 31, 34, 0.7);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }
  @media (min-width : 414px) and (max-width : 767px){
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: rgba(30, 31, 34, 0.7);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }
  @media (max-width: 413px){
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: rgba(30, 31, 34, 0.7);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }
`;

export const HeaderLogo = styled.div`
  display: flex;
  order: 0;
  z-index: 1000;
  
  & > img {
    height: 50px;
  }
`;

export const BurgerIcon = styled.div`
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
  cursor: pointer;
  order: 1;
  
  span {
    display: block;
    height: 3px;
    background-color: white;
    border-radius: 3px;
  }
  @media (max-width: 768px) {
    display: flex;
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  z-index: 100;

  @media (max-width: 768px) {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(30, 31, 34, 0.98);
    width: 100%;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  }
`;

export const CloseMenuButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 36px;
  color: white;
  cursor: pointer;
  z-index: 100;
  transform: translateX(-40px);
  
  &:hover {
    color: #ff5c5c;
  }
`;

export const HeaderLinks = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;

  @media (min-width : 1440px) and (max-width : 1628px){}
  @media (min-width : 1200px) and (max-width : 1439px){}
  @media (min-width : 992px) and (max-width : 1199px){}
  @media (min-width : 768px) and (max-width : 991px){  }
  @media (min-width : 414px) and (max-width : 767px){
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  @media (max-width: 413px){
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
`;

export const Link = styled.a`
  display: flex;
  font-size: 22px;
  color: #FFFFFF;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    filter: grayscale(0%) brightness(0%) sepia(1) hue-rotate(360deg) saturate(300%);
  }
`;


export const HeaderLogin = styled.div`
  display: flex;
  gap: 30px;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
 
  & > img{
    height: 50px;

    &:hover {
      filter: grayscale(0%) brightness(0%) sepia(1) hue-rotate(360deg) saturate(300%);
    }
  }
`;
