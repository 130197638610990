import React, {useEffect, useState} from "react";
import Logo from '../../assets/img/logo.svg';
import Discord from '../../assets/img/icons/discord.svg';
import {
    HeaderContainer,
    HeaderLogo,
    HeaderLinks,
    HeaderLogin,
    Link,
    Button,
    BurgerIcon,
    MenuContainer,
    CloseMenuButton
} from "./styles";

export const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleDiscordClick = () => {
        window.open("https://discord.gg/tQ9JHURh", "_blank");
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    useEffect(() => {
        if (isMenuOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }

        return () => {
            document.body.style.overflow = "auto";
        };
    }, [isMenuOpen]);

    return(
        <HeaderContainer>
            <HeaderLogo>
                <img src={Logo} alt=''/>
            </HeaderLogo>
            {!isMenuOpen && (
                <BurgerIcon onClick={toggleMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                </BurgerIcon>
            )}
            <MenuContainer isOpen={isMenuOpen}>
                {isMenuOpen && (
                    <CloseMenuButton onClick={closeMenu}>X</CloseMenuButton>
                )}
                <HeaderLinks>
                    <Link onClick={() => {
                        scrollToSection("server-info");
                        closeMenu();
                    }}>О сервере</Link>
                    <Link onClick={() => {
                        scrollToSection("rules");
                        closeMenu();
                    }}>Правила</Link>
                    <Link onClick={() => {
                        scrollToSection("features");
                        closeMenu();
                    }}>Особенности</Link>
                    <Link onClick={() => {
                        scrollToSection("map");
                        closeMenu();
                    }}>Карта</Link>

                </HeaderLinks>
            </MenuContainer>
            <HeaderLogin>
                {!isMenuOpen && (
                    <Button onClick={handleDiscordClick}>
                        <img src={Discord} alt='' />
                    </Button>
                )}
            </HeaderLogin>
        </HeaderContainer>
    )
}