import React from "react";
import AboutImg from '../../../assets/img/about.png';
import {
    AboutContainer,
    AboutContent,
    AboutDescription
} from './styles'

export const About = () => {

    return(
        <AboutContainer>
            <AboutContent>
                <AboutDescription>
                    <img src={AboutImg} alt=''/>
                    <div>
                        <h2>О сервере</h2>
                        <span>
                            <span>RUBEZH</span> —  это сервер для DayZ, который сохраняет дух оригинальной игры.
                            На нашем сервере нет оружия и брони, превосходящих по характеристикам оригинальные элементы,
                            представленные разработчиками.
                        </span>
                    </div>
                </AboutDescription>
            </AboutContent>
        </AboutContainer>
    )
}