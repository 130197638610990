import styled from "styled-components";
import MainPageBgImg from '../../../assets/img/bg.png';

export const MainPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-image: url(${MainPageBgImg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const LetsPlayContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 650px;
  bottom: 15%;
  left: 50px;
  transform: translateX(-15%, -50px);
  
  & > p {
    line-height: 50px;
    padding: 0;
    margin: 0;
    color: #FFFFFF;
    font-size: 66px;
  }
  
  & > div {
    margin-top: 50px;
    padding: 10px 100px;
    background: rgba(30, 31, 34, 0.5);
    backdrop-filter: blur(10px);
    cursor: pointer;
    width: max-content;
    
    & > span {
      color: #FFFFFF;
      font-size: 36px;
    }

    &:hover {
      background: rgba(208, 18, 18, 0.5);
      backdrop-filter: blur(10px);
    }
  }

  @media (min-width : 1440px) and (max-width : 1628px){
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 650px;
    bottom: 15%;
    left: 50px;
    transform: translateX(-15%, -50px);

    & > p {
      line-height: 50px;
      padding: 0;
      margin: 0;
      color: #FFFFFF;
      font-size: 66px;
    }

    & > div {
      margin-top: 50px;
      padding: 10px 100px;
      background: rgba(30, 31, 34, 0.5);
      backdrop-filter: blur(10px);
      cursor: pointer;
      width: max-content;

      & > span {
        color: #FFFFFF;
        font-size: 36px;
      }

      &:hover {
        background: rgba(208, 18, 18, 0.5);
        backdrop-filter: blur(10px);
      }
    }
  }
  @media (min-width : 1200px) and (max-width : 1439px){
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 550px;
    bottom: 15%;
    left: 50px;
    transform: translateX(-15%, -50px);

    & > p {
      line-height: 50px;
      padding: 0;
      margin: 0;
      color: #FFFFFF;
      font-size: 60px;
    }

    & > div {
      margin-top: 50px;
      padding: 10px 100px;
      background: rgba(30, 31, 34, 0.5);
      backdrop-filter: blur(10px);
      cursor: pointer;
      width: max-content;

      & > span {
        color: #FFFFFF;
        font-size: 36px;
      }

      &:hover {
        background: rgba(208, 18, 18, 0.5);
        backdrop-filter: blur(10px);
      }
    }
  }
  @media (min-width : 992px) and (max-width : 1199px){
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 500px;
    bottom: 15%;
    left: 50px;
    transform: translateX(-15%, -50px);

    & > p {
      line-height: 50px;
      padding: 0;
      margin: 0;
      color: #FFFFFF;
      font-size: 50px;
    }

    & > div {
      margin-top: 50px;
      padding: 10px 90px;
      background: rgba(30, 31, 34, 0.5);
      backdrop-filter: blur(10px);
      cursor: pointer;
      width: max-content;

      & > span {
        color: #FFFFFF;
        font-size: 34px;
      }

      &:hover {
        background: rgba(208, 18, 18, 0.5);
        backdrop-filter: blur(10px);
      }
    }
  }
  @media (min-width : 768px) and (max-width : 991px){
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 500px;
    bottom: 15%;
    left: 50px;
    transform: translateX(-15%, -50px);

    & > p {
      line-height: 50px;
      padding: 0;
      margin: 0;
      color: #FFFFFF;
      font-size: 50px;
    }

    & > div {
      margin-top: 50px;
      padding: 10px 90px;
      background: rgba(30, 31, 34, 0.5);
      backdrop-filter: blur(10px);
      cursor: pointer;
      width: max-content;

      & > span {
        color: #FFFFFF;
        font-size: 34px;
      }

      &:hover {
        background: rgba(208, 18, 18, 0.5);
        backdrop-filter: blur(10px);
      }
    }
  }
  @media (min-width : 414px) and (max-width : 767px){
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    bottom: 10%;
    left: 0;
    transform: none;
    box-sizing: border-box;

    & > p {
      display: flex;
      text-align: center;
      line-height: 50px;
      padding: 0;
      margin: 0;
      color: #FFFFFF;
      font-size: 38px;
    }

    & > div {
      display: none;
    }
  }
  @media (max-width: 413px){
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    bottom: 10%;
    left: 0;
    transform: none;
    box-sizing: border-box;

    & > p {
      display: flex;
      text-align: center;
      line-height: 50px;
      padding: 0;
      margin: 0;
      color: #FFFFFF;
      font-size: 38px;
    }

    & > div {
      display: none;
    }
  }
`;