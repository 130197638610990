import styled, {keyframes} from "styled-components";

const rotate3D = keyframes`
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
`;

export const AdvantagesContainer = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 50px;

  @media (min-width : 1440px) and (max-width : 1628px){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 50px;
  }
  @media (min-width : 1200px) and (max-width : 1439px){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 50px;
  }
  @media (min-width : 992px) and (max-width : 1199px){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 30px;
  }
  @media (min-width : 768px) and (max-width : 991px){
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 30px;
  }
  @media (min-width : 414px) and (max-width : 767px){
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 30px;
  }
  @media (max-width: 413px){
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 30px;
  }
`;

export const AdvantagesBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 30px;
  width: 100%;
  border-left: 1px solid #2F2F2FFF;
  border-right: 1px solid #2F2F2FFF;
  gap: 10px;
  cursor: pointer;

  perspective: 1000px;
  
  & > img{
    height: 50px;
    transform-style: preserve-3d;
  }

  &:hover > img {
    animation: ${rotate3D} 1s linear infinite;
  }
  
  & > span {
    text-align: center;
    color: #FFFFFF;
    font-size: 24px;
  }
  
  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4),
  &:nth-child(5) {
    border-left: none;
  }

  @media (min-width : 1440px) and (max-width : 1628px){
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 30px;
    width: 100%;
    border-left: 1px solid #2F2F2FFF;
    border-right: 1px solid #2F2F2FFF;
    gap: 10px;
    cursor: pointer;

    perspective: 1000px;

    & > img{
      height: 45px;
      transform-style: preserve-3d;
    }

    &:hover > img {
      animation: ${rotate3D} 1s linear infinite;
    }

    & > span {
      text-align: center;
      color: #FFFFFF;
      font-size: 22px;
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      border-left: none;
    }
  }
  @media (min-width : 1200px) and (max-width : 1439px){
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 30px;
    width: 100%;
    border-left: 1px solid #2F2F2FFF;
    border-right: 1px solid #2F2F2FFF;
    gap: 10px;
    cursor: pointer;

    perspective: 1000px;

    & > img{
      height: 45px;
      transform-style: preserve-3d;
    }

    &:hover > img {
      animation: ${rotate3D} 1s linear infinite;
    }

    & > span {
      text-align: center;
      color: #FFFFFF;
      font-size: 22px;
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      border-left: none;
    }
  }
  @media (min-width : 992px) and (max-width : 1199px){
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 30px;
    width: 100%;
    border-left: 1px solid #2F2F2FFF;
    border-right: 1px solid #2F2F2FFF;
    gap: 10px;
    cursor: pointer;

    perspective: 1000px;

    & > img{
      height: 40px;
      transform-style: preserve-3d;
    }

    &:hover > img {
      animation: ${rotate3D} 1s linear infinite;
    }

    & > span {
      text-align: center;
      color: #FFFFFF;
      font-size: 20px;
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      border-left: none;
    }
  }
  @media (min-width : 768px) and (max-width : 991px){
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 30px;
    width: 100%;
    border-top: 1px solid #2F2F2FFF;
    border-right: none;
    border-left: none;
    gap: 10px;
    cursor: pointer;
    box-sizing: border-box;

    perspective: 1000px;

    & > img{
      height: 40px;
      transform-style: preserve-3d;
    }

    &:hover > img {
      animation: ${rotate3D} 1s linear infinite;
    }

    & > span {
      text-align: center;
      color: #FFFFFF;
      font-size: 20px;
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      border-left: none;
    }
  }
  @media (min-width : 414px) and (max-width : 767px){
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 30px;
    width: 100%;
    border-top: 1px solid #2F2F2FFF;
    border-right: none;
    border-left: none;
    gap: 10px;
    cursor: pointer;
    box-sizing: border-box;

    perspective: 1000px;

    & > img{
      height: 40px;
      transform-style: preserve-3d;
    }

    &:hover > img {
      animation: ${rotate3D} 1s linear infinite;
    }

    & > span {
      text-align: center;
      color: #FFFFFF;
      font-size: 20px;
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      border-left: none;
    }
  }
  @media (max-width: 413px){
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 30px;
    width: 100%;
    border-top: 1px solid #2F2F2FFF;
    border-right: none;
    border-left: none;
    gap: 10px;
    cursor: pointer;
    box-sizing: border-box;

    perspective: 1000px;

    & > img{
      height: 40px;
      transform-style: preserve-3d;
    }

    &:hover > img {
      animation: ${rotate3D} 1s linear infinite;
    }

    & > span {
      text-align: center;
      color: #FFFFFF;
      font-size: 20px;
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      border-left: none;
    }
  }
`;