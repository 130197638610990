import styled from "styled-components";

export const RulesContainer = styled.div`
  display: flex;
  border-top: 1px solid #2F2F2FFF;
`;

export const RulesContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 50px;
  width: 100%;
  border-left: 1px solid #2F2F2FFF;
  border-right: 1px solid #2F2F2FFF;
  box-sizing: border-box;

  @media (min-width : 1440px) and (max-width : 1628px){}
  @media (min-width : 1200px) and (max-width : 1439px){}
  @media (min-width : 992px) and (max-width : 1199px){
    display: flex;
    flex-direction: column;
    margin: 0 30px;
    width: 100%;
    border-left: 1px solid #2F2F2FFF;
    border-right: 1px solid #2F2F2FFF;
    box-sizing: border-box;
  }
  @media (min-width : 768px) and (max-width : 991px){
    display: flex;
    flex-direction: column;
    margin: 0 30px;
    width: 100%;
    border-left: none;
    border-right: none;
    box-sizing: border-box;
  }
  @media (min-width : 414px) and (max-width : 767px){
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 100%;
    border-left: none;
    border-right: none;
    box-sizing: border-box;
  }
  @media (max-width: 413px){
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 100%;
    border-left: none;
    border-right: none;
    box-sizing: border-box;
  }
`;

export const Clear = styled.div`
  margin: 0;
  height: 130px;
  border-bottom: 1px solid #2F2F2FFF;

  @media (min-width : 1440px) and (max-width : 1628px){}
  @media (min-width : 1200px) and (max-width : 1439px){}
  @media (min-width : 992px) and (max-width : 1199px){}
  @media (min-width : 768px) and (max-width : 991px){
    display: none;
  }
  @media (min-width : 414px) and (max-width : 767px){
    display: none;
  }
  @media (max-width: 413px){
    display: none;
  }
`;

export const ClearOne = styled.div`
  margin: 0;
  height: 130px;
  border-top: 1px solid #2F2F2FFF;

  @media (min-width : 1440px) and (max-width : 1628px){}
  @media (min-width : 1200px) and (max-width : 1439px){}
  @media (min-width : 992px) and (max-width : 1199px){}
  @media (min-width : 768px) and (max-width : 991px){
    display: none;
  }
  @media (min-width : 414px) and (max-width : 767px){
    display: none;
  }
  @media (max-width: 413px){
    display: none;
  }
`;

export const RulesTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 50px 50px;
  
  & > h2 {
    margin: 0;
    padding: 0;
    color: #FFFFFF;
    font-size: 42px;
  }

  @media (min-width : 1440px) and (max-width : 1628px){
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 50px 50px;

    & > h2 {
      margin: 0;
      padding: 0;
      color: #FFFFFF;
      font-size: 40px;
    }
  }
  @media (min-width : 1200px) and (max-width : 1439px){}
  @media (min-width : 992px) and (max-width : 1199px){
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 50px 30px;

    & > h2 {
      margin: 0;
      padding: 0;
      color: #FFFFFF;
      font-size: 36px;
    }
  }
  @media (min-width : 768px) and (max-width : 991px){
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 50px 30px;

    & > h2 {
      margin: 0;
      padding: 0;
      color: #FFFFFF;
      font-size: 32px;
    }
  }
  @media (min-width : 414px) and (max-width : 767px){
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 50px 30px;

    & > h2 {
      margin: 0;
      padding: 0;
      color: #FFFFFF;
      font-size: 30px;
    }
  }
  @media (max-width: 413px){
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 50px 30px;

    & > h2 {
      margin: 0;
      padding: 0;
      color: #FFFFFF;
      font-size: 30px;
    }
  }
`;

export const RulesAccordion = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid #2F2F2FFF;

  @media (min-width : 1440px) and (max-width : 1628px){}
  @media (min-width : 1200px) and (max-width : 1439px){}
  @media (min-width : 992px) and (max-width : 1199px){}
  @media (min-width : 768px) and (max-width : 991px){}
  @media (min-width : 414px) and (max-width : 767px){}
  @media (max-width: 413px){}
`;

export const RulesAccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;
  width: 100%;
  text-align: left;
  font-size: 56px;
  color: #FFFFFF;
  cursor: pointer;
  box-sizing: border-box;
  
  & > img {
    height: 40px;
  }
  
  & span {
    color: ${(props) => (props.isActive ? "#D01212FF" : "#FFFFFF")};
    font-weight: ${(props) => (props.isActive ? "" : "normal")};
    transition: color 0.3s ease, font-weight 0.3s ease;
  }

  @media (min-width : 1440px) and (max-width : 1628px){
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;
    width: 100%;
    text-align: left;
    font-size: 52px;
    color: #FFFFFF;
    cursor: pointer;
    box-sizing: border-box;

    & > img {
      height: 40px;
    }

    & span {
      color: ${(props) => (props.isActive ? "#D01212FF" : "#FFFFFF")};
      font-weight: ${(props) => (props.isActive ? "" : "normal")};
      transition: color 0.3s ease, font-weight 0.3s ease;
      font-size: 50px;
    }
  }
  @media (min-width : 1200px) and (max-width : 1439px){}
  @media (min-width : 992px) and (max-width : 1199px){
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;
    width: 100%;
    text-align: left;
    color: #FFFFFF;
    cursor: pointer;
    box-sizing: border-box;

    & > img {
      height: 35px;
    }

    & span {
      color: ${(props) => (props.isActive ? "#D01212FF" : "#FFFFFF")};
      font-weight: ${(props) => (props.isActive ? "" : "normal")};
      transition: color 0.3s ease, font-weight 0.3s ease;
      font-size: 46px;
    }
  }
  @media (min-width : 768px) and (max-width : 991px){
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
    width: 100%;
    text-align: left;
    color: #FFFFFF;
    cursor: pointer;
    box-sizing: border-box;

    & > img {
      height: 30px;
    }

    & span {
      color: ${(props) => (props.isActive ? "#D01212FF" : "#FFFFFF")};
      font-weight: ${(props) => (props.isActive ? "" : "normal")};
      transition: color 0.3s ease, font-weight 0.3s ease;
      font-size: 42px;
    }
  }
  @media (min-width : 414px) and (max-width : 767px){
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
    width: 100%;
    text-align: left;
    color: #FFFFFF;
    cursor: pointer;
    box-sizing: border-box;

    & > img {
      height: 20px;
    }

    & span {
      color: ${(props) => (props.isActive ? "#D01212FF" : "#FFFFFF")};
      font-weight: ${(props) => (props.isActive ? "" : "normal")};
      transition: color 0.3s ease, font-weight 0.3s ease;
      font-size: 28px;
    }
  }
  @media (max-width: 413px){
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
    width: 100%;
    text-align: left;
    color: #FFFFFF;
    cursor: pointer;
    box-sizing: border-box;

    & > img {
      height: 20px;
    }

    & span {
      color: ${(props) => (props.isActive ? "#D01212FF" : "#FFFFFF")};
      font-weight: ${(props) => (props.isActive ? "" : "normal")};
      transition: color 0.3s ease, font-weight 0.3s ease;
      font-size: 28px;
    }
  }
`;

export const RulesAccordionItem = styled.div`
  width: 100%;
  border-top: 1px solid #2F2F2FFF;
  border-bottom: 1px solid #2F2F2FFF;
  padding: 0 50px;
  box-sizing: border-box;

  @media (min-width : 1440px) and (max-width : 1628px){}
  @media (min-width : 1200px) and (max-width : 1439px){}
  @media (min-width : 992px) and (max-width : 1199px){}
  @media (min-width : 768px) and (max-width : 991px){}
  @media (min-width : 414px) and (max-width : 767px){}
  @media (max-width: 413px){}
`;

export const RulesAccordionContent = styled.div`
  white-space: pre-line;
  display: ${({ open }) => (open ? "block" : "none")};
  padding: 50px 90px;
  height: ${({ open }) => (open ? "auto" : "0")};
  color: #FFFFFF;
  overflow: hidden;
  font-size: 26px;

  @media (min-width : 1440px) and (max-width : 1628px){
    white-space: pre-line;
    display: ${({ open }) => (open ? "block" : "none")};
    padding: 50px 80px;
    height: ${({ open }) => (open ? "auto" : "0")};
    color: #FFFFFF;
    overflow: hidden;
    font-size: 24px;
  }
  @media (min-width : 1200px) and (max-width : 1439px){}
  @media (min-width : 992px) and (max-width : 1199px){
    white-space: pre-line;
    display: ${({ open }) => (open ? "block" : "none")};
    padding: 30px 80px;
    height: ${({ open }) => (open ? "auto" : "0")};
    color: #FFFFFF;
    overflow: hidden;
    font-size: 24px;
  }
  @media (min-width : 768px) and (max-width : 991px){
    white-space: pre-line;
    display: ${({ open }) => (open ? "block" : "none")};
    padding: 30px 60px;
    height: ${({ open }) => (open ? "auto" : "0")};
    color: #FFFFFF;
    overflow: hidden;
    font-size: 22px;
  }
  @media (min-width : 414px) and (max-width : 767px){
    white-space: pre-line;
    display: ${({ open }) => (open ? "block" : "none")};
    padding: 10px 0 20px 0;
    height: ${({ open }) => (open ? "auto" : "0")};
    color: #FFFFFF;
    overflow: hidden;
    font-size: 22px;
  }
  @media (max-width: 413px){
    white-space: pre-line;
    display: ${({ open }) => (open ? "block" : "none")};
    padding: 10px 0 20px 0;
    height: ${({ open }) => (open ? "auto" : "0")};
    color: #FFFFFF;
    overflow: hidden;
    font-size: 22px;
  }
`;
