import React from "react";
import Logo from '../../../assets/img/logo.svg'
import Discord from "../../../assets/img/icons/discord.svg";
import {
    FooterContainer,
    FooterContent,
    FooterLogo,
    FooterLink,
    Link,
    FooterLogin,
    Button
} from "./styles";

export const Footer = () => {
    const scrollToSectionFooter = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleLogoClick = () => {
        window.open("https://discord.gg/tQ9JHURh", "_blank");
    };

    return(
        <FooterContainer>
            <FooterContent>
                <FooterLogo src={Logo}/>
                <FooterLink>
                    <Link onClick={() => scrollToSectionFooter("server-info")}>О сервере</Link>
                    <Link onClick={() => scrollToSectionFooter("rules")}>Правила</Link>
                    <Link onClick={() => scrollToSectionFooter("features")}>Особенности</Link>
                    <Link onClick={() => scrollToSectionFooter("map")}>Карта</Link>
                </FooterLink>
                <FooterLogin>
                    <Button onClick={handleLogoClick}>
                        <img src={Discord} alt=''/>
                    </Button>
                </FooterLogin>
            </FooterContent>
        </FooterContainer>
    )
}