import React from "react";
import {Header} from "../../../components/header";
import {
    LetsPlayContainer,
    MainPageContainer
} from './styles'

export const MainPage = () => {
    const handlePlayClick = () => {
        const ip = "92.38.222.39";
        const port = "2416";
        window.location.href = `steam://run/221100//-connect=${ip};port=${port}`;
    };

    return(
        <MainPageContainer>
            <Header/>
            <LetsPlayContainer>
                <p>Когда мир рушится, только сильнейшие выживут. Ты готов?</p>
                <div onClick={handlePlayClick}>
                    <span>Играть</span>
                </div>
            </LetsPlayContainer>
        </MainPageContainer>
    )
}