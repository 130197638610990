import React from "react";
import Fire from '../../../assets/img/icons/fire.svg';
import Economy from '../../../assets/img/icons/economy.svg';
import Loot from '../../../assets/img/icons/loots.svg';
import Hammer from '../../../assets/img/icons/hammer.svg';
import FPS from '../../../assets/img/icons/fps.svg';
import {
    AdvantagesContainer,
    AdvantagesBlock
} from './styles'

export const Advantages = () => {

    return(
        <AdvantagesContainer id='server-info'>
            <AdvantagesBlock>
                <img src={Fire} alt=''/>
                <span>Самый минимум модов</span>
            </AdvantagesBlock>
            <AdvantagesBlock>
                <img src={Economy} alt=''/>
                <span>Переработанная экономика сервера</span>
            </AdvantagesBlock>
            <AdvantagesBlock>
                <img src={Loot} alt=''/>
                <span>Переработанная система лутинга</span>
            </AdvantagesBlock>
            <AdvantagesBlock>
                <img src={Hammer} alt=''/>
                <span>Расширенные возможности строительства</span>
            </AdvantagesBlock>
            <AdvantagesBlock>
                <img src={FPS} alt=''/>
                <span>FPS+</span>
            </AdvantagesBlock>
        </AdvantagesContainer>
    )
}