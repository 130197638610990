import {Site} from "./pages/site";

function App() {
    return (
        <>
            <Site/>
        </>
    );
}

export default App;
