import styled from "styled-components";

export const CopyContainer = styled.div`
  display: flex;
  border-top: 1px solid #2F2F2FFF;
`;

export const CopyContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 50px;
  padding: 30px;
  width: 100%;
  border-left: 1px solid #2F2F2FFF;
  border-right: 1px solid #2F2F2FFF;
  box-sizing: border-box;

  & > p {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    color: #808080FF;
    font-size: 18px;
    
    & > img {
      margin-right: 10px;
      height: 18px;
    }
  }

  @media (min-width : 1440px) and (max-width : 1628px){}
  @media (min-width : 1200px) and (max-width : 1439px){}
  @media (min-width : 992px) and (max-width : 1199px){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 30px;
    padding: 20px;
    width: 100%;
    border-left: 1px solid #2F2F2FFF;
    border-right: 1px solid #2F2F2FFF;
    box-sizing: border-box;

    & > p {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 0 auto;
      color: #808080FF;
      font-size: 16px;

      & > img {
        margin-right: 10px;
        height: 16px;
      }
    }
  }
  @media (min-width : 768px) and (max-width : 991px){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 30px;
    padding: 20px;
    width: 100%;
    border-left: none;
    border-right: none;
    box-sizing: border-box;

    & > p {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 0 auto;
      color: #808080FF;
      font-size: 16px;

      & > img {
        margin-right: 10px;
        height: 16px;
      }
    }
  }
  @media (min-width : 414px) and (max-width : 767px){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 30px;
    padding: 20px;
    width: 100%;
    border-left: none;
    border-right: none;
    box-sizing: border-box;

    & > p {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 0 auto;
      color: #808080FF;
      font-size: 16px;

      & > img {
        margin-right: 10px;
        height: 16px;
      }
    }
  }
  @media (max-width: 413px){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 30px;
    padding: 20px;
    width: 100%;
    border-left: none;
    border-right: none;
    box-sizing: border-box;

    & > p {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 0 auto;
      color: #808080FF;
      font-size: 16px;

      & > img {
        margin-right: 10px;
        height: 16px;
      }
    }
  }
`;
