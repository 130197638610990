import styled from "styled-components";

export const FooterContainer = styled.div`
  display: flex;
  border-top: 1px solid #2F2F2FFF;
`;

export const FooterContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 50px;
  padding: 70px 30px;
  width: 100%;
  border-left: 1px solid #2F2F2FFF;
  border-right: 1px solid #2F2F2FFF;
  box-sizing: border-box;

  @media (min-width : 1440px) and (max-width : 1628px){}
  @media (min-width : 1200px) and (max-width : 1439px){}
  @media (min-width : 992px) and (max-width : 1199px){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 30px;
    padding: 60px 30px;
    width: 100%;
    border-left: 1px solid #2F2F2FFF;
    border-right: 1px solid #2F2F2FFF;
    box-sizing: border-box;
  }
  @media (min-width : 768px) and (max-width : 991px){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 60px 30px;
    width: 100%;
    border-left: none;
    border-right: none;
    box-sizing: border-box;
  }
  @media (min-width : 414px) and (max-width : 767px){
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 60px 30px;
    width: 100%;
    border-left: none;
    border-right: none;
    box-sizing: border-box;
  }
  @media (max-width: 413px){
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 60px 30px;
    width: 100%;
    border-left: none;
    border-right: none;
    box-sizing: border-box;
  }
`;

export const FooterLogo = styled.img`
  height: 70px;

  @media (min-width : 1440px) and (max-width : 1628px){}
  @media (min-width : 1200px) and (max-width : 1439px){
    height: 60px;
  }
  @media (min-width : 992px) and (max-width : 1199px){
    height: 60px;
  }
  @media (min-width : 768px) and (max-width : 991px){
    height: 50px;
  }
  @media (min-width : 414px) and (max-width : 767px){
    height: 70px;
  }
  @media (max-width: 413px){
    height: 70px;
  }
`;

export const FooterLink = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;

  @media (min-width : 1440px) and (max-width : 1628px){}
  @media (min-width : 1200px) and (max-width : 1439px){}
  @media (min-width : 992px) and (max-width : 1199px){}
  @media (min-width : 768px) and (max-width : 991px){}
  @media (min-width : 414px) and (max-width : 767px){
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 30px;
  }
  @media (max-width: 413px){
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 30px;
  }
`;

export const Link = styled.a`
  font-size: 22px;
  color: #FFFFFF;
  cursor: pointer;

  &:hover {
    color: rgba(208, 18, 18, 1);
  }

  @media (min-width : 1440px) and (max-width : 1628px){}
  @media (min-width : 1200px) and (max-width : 1439px){
    font-size: 20px;
  }
  @media (min-width : 992px) and (max-width : 1199px){
    font-size: 20px;
  }
  @media (min-width : 768px) and (max-width : 991px){
    font-size: 20px;
  }
  @media (min-width : 414px) and (max-width : 767px){
    font-size: 20px;
  }
  @media (max-width: 413px){
    font-size: 20px;
  }
`;

export const FooterLogin = styled.div`
  display: flex;    
`;
export const Button = styled.div`
  display: flex;
  cursor: pointer;
  
  & > img {
    height: 60px;
  }

  &:hover > img {
    filter: brightness(0) saturate(100%) invert(31%) sepia(69%) saturate(608%) hue-rotate(195deg) brightness(91%) contrast(92%);
  }

  @media (min-width : 1440px) and (max-width : 1628px){}
  @media (min-width : 1200px) and (max-width : 1439px){
    & > img {
      height: 55px;
    }
  }
  @media (min-width : 992px) and (max-width : 1199px){
    & > img {
      height: 55px;
    }
  }
  @media (min-width : 768px) and (max-width : 991px){
    & > img {
      height: 50px;
    }
  }
  @media (min-width : 414px) and (max-width : 767px){
    & > img {
      margin-top: 40px;
      height: 50px;
    }
  }
  @media (max-width: 413px){
    & > img {
      margin-top: 40px;
      height: 50px;
    }
  }
`;