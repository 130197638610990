import React from "react";
import {MainPage} from "./main_page";
import {Advantages} from "./advantages";
import {About} from "./about";
import {Rules} from "./rules";
import {Edge} from "./edge";
import {Map} from "./map";
import {Footer} from "./footer";
import {
    Container
} from "./styles";
import {CopyRight} from "./copyright";

export const Site = () => {
    return(
        <Container>
            <MainPage/>
            <Advantages/>
            <About/>
            <Rules/>
            <Edge/>
            <Map/>
            <Footer/>
            <CopyRight/>
        </Container>
    )
}