import styled from "styled-components";

export const EdgeContainer = styled.div`
  display: flex;
  border-top: 1px solid #2F2F2FFF;
  border-bottom: 1px solid #2F2F2FFF;
`;

export const EdgeContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 50px;
  padding: 70px 50px;
  width: 100%;
  border-left: 1px solid #2F2F2FFF;
  border-right: 1px solid #2F2F2FFF;
  box-sizing: border-box;
  
  & > h2 {
    text-align: center;
    font-size: 42px;
    color: #FFFFFF;
  }

  & ul {
    margin-top: 20px;
    list-style-type: disc;

    & li {
      white-space: pre-line;
      margin-bottom: 15px;
      font-size: 28px;
      color: #FFFFFF;
    }
  }

  @media (min-width : 1440px) and (max-width : 1628px){}
  @media (min-width : 1200px) and (max-width : 1439px){
    display: flex;
    flex-direction: column;
    margin: 0 50px;
    padding: 50px 50px;
    width: 100%;
    border-left: 1px solid #2F2F2FFF;
    border-right: 1px solid #2F2F2FFF;
    box-sizing: border-box;

    & > h2 {
      text-align: center;
      font-size: 42px;
      color: #FFFFFF;
    }

    & ul {
      margin-top: 20px;
      list-style-type: disc;

      & li {
        white-space: pre-line;
        margin-bottom: 15px;
        font-size: 26px;
        color: #FFFFFF;
      }
    }
  }
  @media (min-width : 992px) and (max-width : 1199px){
    display: flex;
    flex-direction: column;
    margin: 0 30px;
    padding: 50px 50px;
    width: 100%;
    border-left: 1px solid #2F2F2FFF;
    border-right: 1px solid #2F2F2FFF;
    box-sizing: border-box;

    & > h2 {
      text-align: center;
      font-size: 36px;
      color: #FFFFFF;
    }

    & ul {
      margin-top: 20px;
      list-style-type: disc;

      & li {
        white-space: pre-line;
        margin-bottom: 15px;
        font-size: 24px;
        color: #FFFFFF;
      }
    }
  }
  @media (min-width : 768px) and (max-width : 991px){
    display: flex;
    flex-direction: column;
    margin: 0 30px;
    padding: 50px 30px;
    width: 100%;
    border-left: none;
    border-right: none;
    box-sizing: border-box;

    & > h2 {
      text-align: center;
      font-size: 36px;
      color: #FFFFFF;
    }

    & ul {
      margin-top: 20px;
      list-style-type: disc;

      & li {
        white-space: pre-line;
        margin-bottom: 15px;
        font-size: 24px;
        color: #FFFFFF;
      }
    }
  }
  @media (min-width : 414px) and (max-width : 767px){
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 50px 30px;
    width: 100%;
    border-left: none;
    border-right: none;
    box-sizing: border-box;

    & > h2 {
      text-align: center;
      font-size: 30px;
      color: #FFFFFF;
    }

    & ul {
      margin-top: 20px;
      list-style-type: disc;

      & li {
        white-space: pre-line;
        margin-bottom: 15px;
        font-size: 22px;
        color: #FFFFFF;
      }
    }
  }
  @media (max-width: 413px){
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 50px 30px;
    width: 100%;
    border-left: none;
    border-right: none;
    box-sizing: border-box;

    & > h2 {
      text-align: center;
      font-size: 30px;
      color: #FFFFFF;
    }

    & ul {
      margin-top: 20px;
      list-style-type: disc;

      & li {
        white-space: pre-line;
        margin-bottom: 15px;
        font-size: 22px;
        color: #FFFFFF;
      }
    }
  }
`;