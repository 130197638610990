import React from "react";
import {
    MapContainer,
    MapContent
} from './styles';

export const Map = () => {

    return(
        <MapContainer id='map'>
            <MapContent>
                <h2>Карта SAKHAL</h2>
                <iframe
                    src="https://dayz.xam.nu/sakhal#6975.00;6750.00;1"
                    title="Карта SAKHAL"
                />
            </MapContent>
        </MapContainer>
    )
}