import styled from 'styled-components'
import BgImg from '../../assets/img/main-bg.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-image: url(${BgImg});
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;