import styled from "styled-components";

export const MapContainer = styled.div`
  display: flex;
  height: 100vh;
`;

export const MapContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 50px;
  padding: 70px 50px;
  width: 100%;
  border-left: 1px solid #2F2F2FFF;
  border-right: 1px solid #2F2F2FFF;
  box-sizing: border-box;
  
  & > h2 {
    margin: 0 0 70px 0;
    font-size: 42px;
    color: #FFFFFF;
    text-align: center;
  }
  
  & > iframe {
    width: 85%;
    height: 100%;
    border: none;
  }

  @media (min-width : 1440px) and (max-width : 1628px){
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 50px;
    padding: 70px 50px;
    width: 100%;
    border-left: 1px solid #2F2F2FFF;
    border-right: 1px solid #2F2F2FFF;
    box-sizing: border-box;

    & > h2 {
      margin: 0 0 70px 0;
      font-size: 42px;
      color: #FFFFFF;
      text-align: center;
    }

    & > iframe {
      width: 90%;
      height: 100%;
      border: none;
    }
  }
  @media (min-width : 1200px) and (max-width : 1439px){
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 50px;
    padding: 70px 50px;
    width: 100%;
    border-left: 1px solid #2F2F2FFF;
    border-right: 1px solid #2F2F2FFF;
    box-sizing: border-box;

    & > h2 {
      margin: 0 0 70px 0;
      font-size: 42px;
      color: #FFFFFF;
      text-align: center;
    }

    & > iframe {
      width: 90%;
      height: 100%;
      border: none;
    }
  }
  @media (min-width : 992px) and (max-width : 1199px){
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 30px;
    padding: 50px 30px;
    width: 100%;
    border-left: 1px solid #2F2F2FFF;
    border-right: 1px solid #2F2F2FFF;
    box-sizing: border-box;

    & > h2 {
      margin: 0 0 70px 0;
      font-size: 42px;
      color: #FFFFFF;
      text-align: center;
    }

    & > iframe {
      width: 90%;
      height: 100%;
      border: none;
    }
  }
  @media (min-width : 768px) and (max-width : 991px){
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 30px;
    padding: 50px 30px;
    width: 100%;
    border-left: none;
    border-right: none;
    box-sizing: border-box;

    & > h2 {
      margin: 0 0 50px 0;
      font-size: 36px;
      color: #FFFFFF;
      text-align: center;
    }

    & > iframe {
      width: 90%;
      height: 100%;
      border: none;
    }
  }
  @media (min-width : 414px) and (max-width : 767px){
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 30px;
    padding: 50px 0;
    width: 100%;
    border-left: none;
    border-right: none;
    box-sizing: border-box;

    & > h2 {
      margin: 0 0 50px 0;
      font-size: 30px;
      color: #FFFFFF;
      text-align: center;
    }

    & > iframe {
      width: 90%;
      height: 100%;
      border: none;
    }
  }
  @media (max-width: 413px){
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 30px;
    padding: 50px 0;
    width: 100%;
    border-left: none;
    border-right: none;
    box-sizing: border-box;

    & > h2 {
      margin: 0 0 50px 0;
      font-size: 30px;
      color: #FFFFFF;
      text-align: center;
    }

    & > iframe {
      width: 90%;
      height: 100%;
      border: none;
    }
  }
`;