import React from "react";
import Copy from '../../../assets/img/icons/copyright.svg';
import {
    CopyContainer,
    CopyContent
} from "./styles";

export const CopyRight = () => {
    const currentYear = new Date().getFullYear();

    return(
        <CopyContainer>
            <CopyContent>
                <p><img src={Copy} alt=''/> {currentYear} RUBEZH</p>
            </CopyContent>
        </CopyContainer>
    )
}