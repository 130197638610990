import styled from "styled-components";

export const AboutContainer = styled.div`
  display: flex;
  border-top: 1px solid #2F2F2FFF;
`;

export const AboutContent = styled.div`
  display: flex;
  margin: 0 50px;
  width: 100%;
  border-left: 1px solid #2F2F2FFF;
  border-right: 1px solid #2F2F2FFF;
  box-sizing: border-box;

  @media (min-width : 1440px) and (max-width : 1628px){}
  @media (min-width : 1200px) and (max-width : 1439px){}
  @media (min-width : 992px) and (max-width : 1199px){
    display: flex;
    margin: 0 30px;
    width: 100%;
    border-left: 1px solid #2F2F2FFF;
    border-right: 1px solid #2F2F2FFF;
    box-sizing: border-box;
  }
  @media (min-width : 768px) and (max-width : 991px){
    display: flex;
    margin: 0 30px;
    width: 100%;
    border-left: none;
    border-right: none;
    box-sizing: border-box;
  }
  @media (min-width : 414px) and (max-width : 767px){
    display: flex;
    margin: 0 10px;
    width: 100%;
    border-left: none;
    border-right: none;
    box-sizing: border-box;
  }
  @media (max-width: 413px){
    display: flex;
    margin: 0 10px;
    width: 100%;
    border-left: none;
    border-right: none;
    box-sizing: border-box;
  }
`;

export const AboutDescription = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 100px 50px;
  
  & > img {
    width: 40%;
    height: 400px;
    object-fit: cover;
  }
  
  & > div {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 30px;
    
    & > h2 {
      margin: 0;
      padding: 0;
      color: #FFFFFF;
      font-size: 42px;
    }
    
    & > span {
      margin: 0;
      padding: 0;
      color: #FFFFFF;
      font-size: 40px;
      
      & > span {
        color: #D01212FF;
      }
    }
  }

  @media (min-width : 1440px) and (max-width : 1628px){
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 100px 50px;

    & > img {
      width: 45%;
      height: 350px;
      object-fit: cover;
    }

    & > div {
      display: flex;
      flex-direction: column;
      width: 50%;
      gap: 30px;

      & > h2 {
        margin: 0;
        padding: 0;
        color: #FFFFFF;
        font-size: 38px;
      }

      & > span {
        margin: 0;
        padding: 0;
        color: #FFFFFF;
        font-size: 36px;

        & > span {
          color: #D01212FF;
        }
      }
    }
  }
  @media (min-width : 1200px) and (max-width : 1439px){
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 100px 50px;

    & > img {
      width: 45%;
      height: 350px;
      object-fit: cover;
    }

    & > div {
      display: flex;
      flex-direction: column;
      width: 50%;
      gap: 30px;

      & > h2 {
        margin: 0;
        padding: 0;
        color: #FFFFFF;
        font-size: 36px;
      }

      & > span {
        margin: 0;
        padding: 0;
        color: #FFFFFF;
        font-size: 32px;

        & > span {
          color: #D01212FF;
        }
      }
    }
  }
  @media (min-width : 992px) and (max-width : 1199px){
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 100px 30px;

    & > img {
      width: 45%;
      height: 300px;
      object-fit: cover;
    }

    & > div {
      display: flex;
      flex-direction: column;
      width: 50%;
      gap: 30px;

      & > h2 {
        margin: 0;
        padding: 0;
        color: #FFFFFF;
        font-size: 32px;
      }

      & > span {
        margin: 0;
        padding: 0;
        color: #FFFFFF;
        font-size: 26px;

        & > span {
          color: #D01212FF;
        }
      }
    }
  }
  @media (min-width : 768px) and (max-width : 991px){
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 100px 30px;
    gap: 50px;

    & > img {
      width: 100%;
      height: 300px;
      object-fit: cover;
    }

    & > div {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 30px;

      & > h2 {
        margin: 0;
        padding: 0;
        color: #FFFFFF;
        font-size: 32px;
      }

      & > span {
        margin: 0;
        padding: 0;
        color: #FFFFFF;
        font-size: 26px;

        & > span {
          color: #D01212FF;
        }
      }
    }
  }
  @media (min-width : 414px) and (max-width : 767px){
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 100px 20px;
    gap: 50px;

    & > img {
      width: 100%;
      height: 300px;
      object-fit: cover;
    }

    & > div {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 30px;

      & > h2 {
        margin: 0;
        padding: 0;
        color: #FFFFFF;
        font-size: 30px;
      }

      & > span {
        margin: 0;
        padding: 0;
        color: #FFFFFF;
        font-size: 24px;

        & > span {
          color: #D01212FF;
        }
      }
    }
  }
  @media (max-width: 413px){
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 100px 20px;
    gap: 50px;

    & > img {
      width: 100%;
      height: 300px;
      object-fit: cover;
    }

    & > div {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 30px;

      & > h2 {
        margin: 0;
        padding: 0;
        color: #FFFFFF;
        font-size: 30px;
      }

      & > span {
        margin: 0;
        padding: 0;
        color: #FFFFFF;
        font-size: 24px;

        & > span {
          color: #D01212FF;
        }
      }
    }
  }
`;
